.card__header {
    text-align: center;
    border: 1px dashed #d3d3d3;
    padding-inline: 1rem
}

.card__delete-btn {
    font-size: small;
    vertical-align: top;
    border: 1px solid #aaa;
    border-radius: .25rem;
    padding: .125rem;
}